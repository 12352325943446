<template>
    <div class="orderReport">
        <!-- 搜索组件 -->
        <page-search :search-config="searchConfig(searchOptions)" @query-click="handleQueryClick"
            @reset-click="handleResetClick" @export-click="hanldeExportExcel"
            :isShowExport="isShowExport"></page-search>

        <!-- 内容组件，传递 orderList 数据 -->

        <el-card shadow="never" style="margin-top: 10px;">
            <page-content :order-list="orderList" :channel-list="channelList" :isloading="isloading"
                @show-detail="handleShowDetail"></page-content>
            <!-- 分页组件 -->
            <div class="pagination">
                <el-pagination background layout="total, prev, pager, next" :total="total" :page-size="pageSize"
                    :current-page="currentPage" @current-change="handlePageChange" @size-change="handleSizeChange" />
            </div>
        </el-card>

        <!-- 订单详情弹出框 -->
        <page-modal ref="orderModal" :channel-list="channelList" />
    </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import pageSearch from '@/components/pageSearch/pageSearch.vue';
import pageContent from "./c-cpns/page-content.vue";
import pageModal from "./c-cpns/page-modal.vue";
import searchConfig from './config/search.config';
import { ElMessage } from 'element-plus';
import { newTimeFormat, isCrossMonth } from '@/tools/time'

const { proxy } = getCurrentInstance();

// 订单列表数据
const orderList = ref([]);

const isloading = ref(true); // 定义加载状态

//是否显示导出按钮
const isShowExport = ref(true)

// 分页相关状态
const total = ref(0); // 总条数
const pageSize = ref(15); // 每页显示的条数
const currentPage = ref(1); // 当前页码

const searchForm = ref({});
// 处理查询事件
const handleQueryClick = async (formData) => {
    // 检查时间范围是否跨月
    if (isCrossMonth(formData.time_start, formData.time_end)) {
        ElMessage.warning('不允许跨月查询，请选择同一个月的时间范围');
        isloading.value = false;
        return; // 阻止查询
    }
    currentPage.value = 1;
    queryOrderList(formData)
};


const queryOrderList = async (formData) => {
    searchForm.value = formData;
    isloading.value = true;
    try {
        const response = await proxy.$api.orderReport.refundList({
            ...formData,
            page: currentPage.value,
            pageSize: pageSize.value
        });
        orderList.value = response.data.data; // 更新 orderList
        total.value = response.data.pagination.total; // 更新总条数

    } catch (error) {
        ElMessage.error('订单列表获取失败,请稍后重试');
    } finally {
        isloading.value = false; // 结束加载
    }
}

// 处理重置事件
const handleResetClick = (formData) => {
    console.log('接收到重置数据:', formData);
    total.value = 0; // 重置总条数
    currentPage.value = 1; // 重置当前页码
    queryOrderList(searchForm.value);
};

// 处理页码变化
const handlePageChange = (page) => {
    currentPage.value = page;
    queryOrderList(searchForm.value); // 重新查询数据
};

// 处理每页条数变化
const handleSizeChange = (size) => {
    pageSize.value = size;
    currentPage.value = 1; // 重置当前页码
    queryOrderList(searchForm.value); // 重新查询数据
};

// 导出订单列表
const hanldeExportExcel = (formData) => {
    // 检测必填字段是否为空
    if (!formData.time_start || !formData.time_end) {
        ElMessage.warning('请选择时间范围后再导出');
        return; // 阻止导出
    }


    // 生成文件名
    const filename = '订单列表' + newTimeFormat('', 'string');

    // 调用导出接口
    proxy.$api.orderReport.refundExport(formData, filename)
        .then(() => {
            ElMessage({ message: '已提交导出请求，请稍等~', type: 'success' });
        })
        .catch((error) => {
            ElMessage.error('导出失败，请稍后重试');
            console.error('导出失败:', error);
        });
};


// 支付方式
const payTypeList = [
    { value: 1, label: "支付宝" },
    { value: 2, label: "微信" },
    { value: 3, label: "云闪付" }
];

// 支付状态
const orderStatus = [
    { value: 'default', label: '交易完成' },
    { value: '3', label: "支付成功" },
    { value: 'fail', label: "支付失败" },
    { value: '5', label: "部分退款" },
    { value: '6', label: "已退款" }
];

// 合并后的配置对象
const searchOptions = ref({
    channelList: [],
    payTypeList,
    orderStatus
});


// 处理显示订单详情
const orderModal = ref(null); // 获取 page-modal 组件的引用
const handleShowDetail = (orderData) => {
    console.log(orderData);

    orderModal.value.openModal(orderData); // 调用 page-modal 的 openModal 方法
};

// 组件挂载时执行默认查询
onMounted(() => {

    // 默认查询参数
    const defaultSearchForm = {};
    for (const item of searchConfig(searchOptions.value).formItems) {
        defaultSearchForm[item.prop] = item.initialValue ?? '';
    }

    // 触发默认查询
    queryOrderList(defaultSearchForm);

});
</script>

<style lang="less" scoped>
.orderReport {
    .export-excel {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #18bc37;
        cursor: pointer;
        -webkit-user-select: none;
    }

    .export-excel:hover {
        color: #409eff;
    }

    .pagination {
        margin: 1.25rem 0;
        display: flex;
        justify-content: flex-end;
    }
}
</style>